import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header, { PrimaryLink } from "../headers/light.js";

import DesignIllustration from "../../images/design-illustration-2.svg";
import GoogleCloud from "../../images/google-cloud.svg";
import Antler from "../../images/antler.svg";
import AcompanhaDev from "../../images/acompanha-dev.png";
import OpenAI from "../../images/open-ai.svg";
import { PrimaryButton } from "components/misc/Buttons.js";

const Container = tw.div`relative lg:px-20 px-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`relative text-center w-full`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Confiam = tw.div`flex flex-col sm:flex-row md:flex-row lg:flex-row items-center justify-between`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Contact = tw.p`mt-5 lg:mt-8 text-base xl:text-sm`;

const ContactMail = tw.p`mt-1 text-base text-primary-500`;

const TextSM = tw.p`my-1 text-base xl:text-sm`;
const TextXS = tw.p`my-1 text-base xl:text-xs`;

const DireitosBody = tw.div`border-t w-full my-5 lg:my-8 `;

const DireitosText = tw.p`my-5 lg:my-8 `;

const Actions = styled.div`
  ${tw`relative text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  /* button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  } */
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-8 lg:mt-10`}
  img {
    ${tw`mt-4`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Container>
        <TwoColumn>
          <Column>
            <Heading>
              <span style={{ color: "#FF305E" }}>Fique por dentro</span>
            </Heading>
            <Paragraph>
              Acompanhe as novidades do futuro do empreendorismo.
            </Paragraph>

            <Contact>Entre em contato</Contact>
            <ContactMail>contato@gosparta.com.br</ContactMail>
            <DireitosBody>
              <DireitosText>
                Copyright © 2023 Sparta A.I. | Todos os direitos reservados
              </DireitosText>
            </DireitosBody>
          </Column>
        </TwoColumn>
      </Container>
    </>
  );
};
