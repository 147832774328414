import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line

import Contabilizei from "../../images/contabilizei.svg";
import ContaAzul from "../../images/conta-azul.svg";
import Inter from "../../images/inter.svg";
import Hubspot from "../../images/hubspot.svg";
import GoogleCloud from "../../images/google-cloud-parc.svg";
import Stripe from "../../images/stripe.svg";
import Clickup from "../../images/clickup.svg";
import Figma from "../../images/figma.svg";

const Container = tw.div`relative lg:px-20 px-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`relative text-center max-w-lg mx-auto lg:max-w-none`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Parceiros = tw.div`flex flex-col lg:flex-row items-center justify-center`;
const ParceirosDiv = tw.div`flex flex-row`;
const ParceirosDivDiv = tw.div`lg:mr-6 mr-6 lg:p-10 p-6 bg-white rounded-sm mb-6`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const TextSM = tw.p`my-1 text-base xl:text-sm`;
const TextXS = tw.p`my-1 text-base xl:text-xs`;

const Actions = styled.div`
  ${tw`relative text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  /* button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  } */
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-8 lg:mt-10`}
  img {
    ${tw`mt-4`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Container style={{ background: "rgba(255, 191, 205, 0.5)" }}>
        <TwoColumn>
          <Column>
            <Heading>
              <span style={{ color: "#FF305E" }}>
                Descontos de verdade, durante toda a jornada
              </span>
            </Heading>
            <Paragraph>
              Além de funcionalidades incríveis, na plataforma Sparta você
              resgata descontos exclusivos em serviços fundamentais desde os
              primeiros dias de abertura de sua empresa.
            </Paragraph>

            <Parceiros>
              <ParceirosDiv>
                <ParceirosDivDiv>
                  <img
                    src={Contabilizei}
                    alt="Our Customers"
                    width={100}
                    //   height={80}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
                <ParceirosDivDiv>
                  <img
                    src={ContaAzul}
                    alt="Our Customers"
                    width={100}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
              </ParceirosDiv>
              <ParceirosDiv>
                <ParceirosDivDiv>
                  <img
                    src={Inter}
                    alt="Our Customers"
                    width={100}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
                <ParceirosDivDiv>
                  <img
                    src={Hubspot}
                    alt="Our Customers"
                    width={100}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
              </ParceirosDiv>
            </Parceiros>

            <Parceiros>
              <ParceirosDiv>
                <ParceirosDivDiv>
                  <img
                    src={GoogleCloud}
                    alt="Our Customers"
                    width={100}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
                <ParceirosDivDiv>
                  <img
                    src={Stripe}
                    alt="Our Customers"
                    width={100}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
              </ParceirosDiv>
              <ParceirosDiv>
                <ParceirosDivDiv>
                  <img
                    src={Clickup}
                    alt="Our Customers"
                    width={100}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
                <ParceirosDivDiv>
                  <img
                    src={Figma}
                    alt="Our Customers"
                    width={100}
                    style={{ height: "80px" }}
                  />
                </ParceirosDivDiv>
              </ParceirosDiv>
            </Parceiros>
          </Column>
        </TwoColumn>
      </Container>
    </>
  );
};
