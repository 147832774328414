import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import DesignIllustration from "../../images/design-illustration-2.svg";
import ZeusAthenas from "../../images/zeus-athenas.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

const Container = tw.div`relative lg:px-20 px-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:text-left text-center`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-2 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center items-center`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-xs xl:max-w-xs"
                // width={100}
                // height={100}
                src={ZeusAthenas}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </LeftColumn>
          <RightColumn>
            <Heading>
              <span style={{ color: "#FF305E" }}>
                Proteja seu{" "}
                <span style={{ textDecoration: "underline" }}>cap table</span>{" "}
                ou o caixa de sua empresa
              </span>
            </Heading>
            <Paragraph style={{ marginTop: "3%" }}>
              <span style={{ textDecoration: "underline", color: "#FF305E" }}>
                Zeus e Athena
              </span>{" "}
              são mentores virtuais com{" "}
              <span style={{ textDecoration: "underline", color: "#FF305E" }}>
                A.I.
              </span>
              . Por áudio ou texto, eles respondem dúvidas, dão dicas e ensinam
              sobre termos essenciais no dia a dia do empreendedor.
            </Paragraph>
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};
