import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import DesignIllustration from "../../images/design-illustration-2.svg";
import Contrato from "../../images/contrato.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import Slider from "react-slick";
import Testemunha1 from "../../images/testemunha-1.svg";

const Container = tw.div`relative lg:px-20 px-8`;
const TwoColumn = tw.div`relative text-center lg:text-left lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl text-white leading-tight`;
const Name = tw.p`text-white xl:text-lg font-bold`;
const Cargo = tw.p`text-white xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center items-center`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

export default ({ roundedHeaderButton }) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <>
      <Container style={{ backgroundColor: "#FF305E" }}>
        <TwoColumn>
          <TestimonialSlider arrows={false} ref={setSliderRef}>
            <div>
              <Heading>
                “A combinação de conexão com co-fundadores, prevenção de
                conflitos e assistente virtual é algo nunca antes visto no
                mercado. Estou muito animado para usar a Sparta!”
              </Heading>
              <div
                style={{
                  marginTop: "12px",
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div>
                  <img
                    src={Testemunha1}
                    alt="Our Customers"
                    width={100}
                    //   height={80}
                    style={{ height: "80px" }}
                  />
                </div>
                <div style={{ textAlign: "left" }}>
                  <Name>Pedro Lima</Name>
                  <Cargo>Empreendedor</Cargo>
                </div>
              </div>
            </div>
          </TestimonialSlider>
        </TwoColumn>
      </Container>
    </>
  );
};
