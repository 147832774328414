import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Section1 from "components/hero/Section1.js";
import Section2 from "components/hero/Section2.js";
import Section3 from "components/hero/Section3";
import Section4 from "components/hero/Section4";
import Section5 from "components/hero/Section5";
import Section6 from "components/hero/Section6";
import Section7 from "components/hero/Section7";
import Section8 from "components/hero/Section8";
import Section9 from "components/hero/Section9";

export default () => {
  return (
    <AnimationRevealPage>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
    </AnimationRevealPage>
  );
};
