import React from "react";
import GlobalStyles from "styles/GlobalStyles";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";

export default function App() {
  return (
    <>
      <Theme>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>
      </Theme>
    </>
  );
}
